import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export function expandAndCollapseAnimation(
  duration: 'short' | 'long' = 'short'
) {
  return [
    trigger('expandAndCollapse', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate(
          `${duration === 'short' ? 400 : 800}ms cubic-bezier(0.4, 0.0, 0.2, 1)`
        )
      ),
    ]),
  ];
}
